import styled from 'styled-components'

import { theme } from 'styles/theme'

const { fontSizes, media, colors, space } = theme

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors['neutral-background-medium']};
  padding: ${space['spacing-xxl']} 0 ${space['spacing-xxl']} 0;

  h2,
  h2 span {
    font-size: ${fontSizes['font-size-heading-4']};
    padding-bottom: ${space['spacing-sm']};
  }

  @media (${media.desktop}) {
    padding: ${space['spacing-giga']} 0 ${space['spacing-giga']} 0;

    h2,
    h2 span {
      font-size: ${fontSizes['font-size-heading-2']};
      padding-bottom: ${space['spacing-lg']};
    }
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};
  overflow-x: auto;

  @media (${media.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: ${space['spacing-md']};
  gap: ${space['spacing-md']};

  & > span {
    width: 56px;
    height: 56px;
  }

  ul {
    min-height: 120px;
  }

  li {
    list-style-type: disc;
    color: ${colors['neutral-text-weak']};
    margin-left: ${space['spacing-lg']};
  }
`
