import { FC, memo } from 'react'

import { Text, Icon } from '@cash-me/react-components'

import { Card } from 'components/card'
import { Grid, Col } from 'components/grid'

import * as S from './styles'

const ConsortiumTypes: FC = memo(() => (
  <S.Wrapper>
    <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
      <Col col={{ tablet: 10, desktop: 10 }} start={{ tablet: 2, desktop: 2 }}>
        <Text as="h2" size="heading2" weight="medium">
          <Text as="span" size="heading2" weight="medium" color="var(--colors-pink-text-default)">
            Tipos de consórcio
          </Text>{' '}
          da CashMe
        </Text>
        <Text color="var(--colors-neutral-text-default)" css={{ paddingBottom: 'var(--space-spacing-md)' }}>
          Alcance seus objetivos sem juros, sem entrada e com parcelas que se encaixam no seu orçamento. Basta
          selecionar o tipo de consórcio que atende às suas necessidades e um plano com valor e prazo ideais.
        </Text>
        <Text color="var(--colors-neutral-text-default)" css={{ paddingBottom: 'var(--space-spacing-md)' }}>
          Os principais tipos de consórcio da CashMe são:
        </Text>
        <S.CardsWrapper>
          <Card border="neutral-border-default">
            <S.CardContent>
              <Icon name="home" size={28} color="var(--colors-primary-icon-default)" />
              <Text as="h3" size="heading4" weight="medium" color="var(--neutral-text-default)">
                Consórcio imobiliário
              </Text>
              <ul>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Residenciais e comerciais</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Urbanos e rurais</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Galpões e terrenos</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Reforma e construção</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Quitação de financiamento</Text>
                </li>
              </ul>
              <Text size="body1" weight="medium" color="var(--neutral-text-default)">
                80 mil a 1 milhão em até 216 meses
              </Text>
            </S.CardContent>
          </Card>
          <Card border="neutral-border-default">
            <S.CardContent>
              <Icon name="car" size={28} color="var(--colors-primary-icon-default)" />
              <Text as="h3" size="heading4" weight="medium" color="var(--neutral-text-default)">
                Consórcio de carros
              </Text>
              <ul>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">0 KM</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Usados até 10 anos</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">+ Caminhão</Text>
                </li>
              </ul>
              <Text size="body1" weight="medium" color="var(--neutral-text-default)">
                40 mil a 440 mil em até 96 meses
              </Text>
            </S.CardContent>
          </Card>
          <Card border="neutral-border-default">
            <S.CardContent>
              <Icon name="brush" size={28} color="var(--colors-primary-icon-default)" />
              <Text as="h3" size="heading4" weight="medium" color="var(--neutral-text-default)">
                Consórcio de serviços
              </Text>
              <ul>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Pequenas obras</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Cursos</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Procedimentos estéticos</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Viagens</Text>
                </li>
                <li>
                  <Text color="var(--colors-neutral-text-weak)">Festas ou formatura</Text>
                </li>
              </ul>
              <Text size="body1" weight="medium" color="var(--neutral-text-default)">
                7 mil a 20 mil em até 48 meses
              </Text>
            </S.CardContent>
          </Card>
        </S.CardsWrapper>
      </Col>
    </Grid>
  </S.Wrapper>
))

ConsortiumTypes.displayName = 'ConsortiumTypes'

export default ConsortiumTypes
